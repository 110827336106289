@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap');

$head:  'Playfair Display', serif;

#content, .cms-content, .cms-block {
  h1, h2, h3, h4, h5, h6, .lead { font-family: $head !important; }
}

h4.card-title {font-family: $head !important;}
.page-title  h1, .breadcrumb a, .faq-qa-group .card-header a, .pagination .btn  {font-family: $head}

.banner-wrap { position: relative;
  .callout {
    .b-title { font-family: $head; }
    .b-caption { font-family: $head; }
  }
}